footer {
  background: #B7E3FC;
  background-color: #B7E3FC;
  color: #00358f;
  font-family: "GT-Alpina-Fine-Medium"; }
  footer .copyright, footer .cookiealertx {
    font-weight: 500;
    font-family: 'Favorit Regular'; }
  footer a.acceptcookies:hover {
    text-decoration: underline; }
  footer a {
    color: #00358f;
    font-weight: 700; }
  footer .collapsible-md .title::after, footer .collapsible-md .title::before {
    border-bottom: 2px solid #00358f; }
  footer .store .content {
    font-weight: 500;
    font-family: 'Favorit Regular'; }
  footer .store h2 img {
    background: #00358f; }
  footer .store h2 a {
    color: #00358f;
    font-weight: 500;
    font-family: 'Favorit Regular'; }
  footer .store a:hover {
    color: #00358f; }
  footer .container .footer-container .footer-item .title {
    color: #00358f;
    font-weight: 900;
    font-family: 'Favorit Regular';
    font-style: normal; }
  footer ul.social-links li {
    background-image: none; }
  footer ul.menu-footer li a {
    font-family: 'Favorit Regular';
    color: #00358f;
    font-weight: 500; }

.search-results .filter-results {
  display: none; }
